<template>
  <BackofficeBase>
    <body class="dash__back">
      <div class="overlay"></div>
      <div class="dashboard__wrapper">
        <div class="dashboard__main">
          <div class="reports__wrapper">
            <div class="breadcrumbs">
              <ul>
                <li>
                  <router-link :to="{ path: '/backoffice/dashbord' }"
                    >ניהול עובדים</router-link
                  >
                </li>
                <li class="spacer">></li>
                <li class="current">דוחות - {{ full_name }}</li>
              </ul>
            </div>
            <div class="content__reports">
              <div class="filter">
                <form action="">
                  <div class="group__input group__dropdown">
                    <input
                      type="month"
                      v-model="full_month"
                      @change="handleMonthChange"
                    />
                    <!-- <a href="#"><span>מאי 2023</span> <img src="../../assets/img/arrow.svg" alt="arrow"></a>
                                 <div class="dropdown__box" style="display:none;">
                                     <ul>
                                         <li><a href="#">1</a></li>
                                         <li><a href="#">2</a></li>
                                         <li><a href="#">3</a></li>
                                         <li><a href="#">4</a></li>
                                         <li><a href="#">5</a></li>
                                         <li><a href="#">1</a></li>
                                         <li><a href="#">2</a></li>
                                         <li><a href="#">3</a></li>
                                         <li><a href="#">4</a></li>
                                         <li><a href="#">5</a></li>
                                     </ul>
                                 </div> -->
                  </div>
                  <a href="#" class="outline-btn" @click="createPDF"
                    ><span
                      ><svg
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M19 3.5H5C3.9 3.5 3 4.4 3 5.5V19.5C3 20.6 3.9 21.5 5 21.5H19C20.1 21.5 21 20.6 21 19.5V5.5C21 4.4 20.1 3.5 19 3.5ZM9.5 12C9.5 12.8 8.8 13.5 8 13.5H7V15.5H5.5V9.5H8C8.8 9.5 9.5 10.2 9.5 11V12ZM14.5 14C14.5 14.8 13.8 15.5 13 15.5H10.5V9.5H13C13.8 9.5 14.5 10.2 14.5 11V14ZM18.5 11H17V12H18.5V13.5H17V15.5H15.5V9.5H18.5V11ZM12 11H13V14H12V11ZM7 11H8V12H7V11Z"
                          fill="#161616"
                        />
                      </svg>
                    </span>
                    הנפקת דוח חודשי</a
                  >
                </form>
              </div>
              <div class="content">
                <div class="table__wrapper">
                  <table class="regular-table">
                    <tr>
                      <th>תאריך</th>
                      <th>יום</th>
                      <th>פירוט</th>
                      <th>קוד משטח</th>
                      <th>כמות</th>
                      <th>מחיר</th>
                      <th>תשלום</th>
                    </tr>
                    <tr
                      v-for="i in table"
                      :value="i"
                      :key="i"
                      :class="{ selected: i.detail_type === 'total' }"
                    >
                      <td>
                        <p>{{ i.working_date_to_show }}</p>
                      </td>
                      <td>
                        <p>{{ i.day_to_show }}'</p>
                      </td>
                      <td>
                        <p>{{ i.detail_type_he }}</p>
                      </td>
                      <td>
                        <p>{{ i.code }}</p>
                      </td>
                      <td>
                        <p>{{ i.amount }}</p>
                      </td>
                      <td>
                        <p>{{ i.rate }}</p>
                      </td>
                      <td>
                        <p class="extrabold">{{ i.payment }}</p>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div class="selected__box">
              <h6>סך הכל ימי עבודה:</h6>
              <div class="double__select">
                <div class="elem__select">
                  <p>
                    סך הכל ימי עבודה: <span>{{ number_of_working_days }}</span>
                  </p>
                </div>
                <div class="elem__select">
                  <p>
                    סך הכל תקבולים:<span>{{ total_payment }}</span>
                  </p>
                </div>
                <div class="elem__select">
                  <p>
                    סך הכל תשלום שעתי:<span>{{
                      total_payment_for_minimum_daily_hours
                    }}</span>
                  </p>
                </div>
                <!-- <div class="elem__select">
                  <p>
                    הלוואות ומפרעות: <span>{{ total_loans_refund }}</span>
                  </p>
                </div> -->
                <div class="elem__select">
                  <p>
                    מפרעות: <span>{{ prepay_type }}</span>
                  </p>
                </div>
                <div class="elem__select">
                  <p>
                    הלוואות : <span>{{ loan_type }}</span>
                  </p>
                </div>
                <div class="elem__select">
                  <p>
                    סך הכל פרמיה: <span>{{ total_premium }}</span>
                  </p>
                </div>
                <div class="elem__select">
                  <p>
                    תשלום על ימי נסיעות: <span>{{ travel_expenses }}</span>
                  </p>
                </div>
                <div class="elem__select">
                  <p>
                    מספר ימי נסיעות: <span>{{ travel_expenses_days }}</span>
                  </p>
                </div>
              </div>
              <div class="total">
                <p>
                  סך הכל לתשלום: <span>{{ amount_to_pay }}</span>
                </p>
              </div>
            </div>
          </div>
          <BackofficeSidebar></BackofficeSidebar>
        </div>
        <div class="modal__wrapper" style="display: none">
          <div class="inner__modal">
            <div class="modal__box export">
              <a href="#"><img src="img/close.svg" alt="close" /></a>
              <form action="">
                <h2>הנפקת דו”ח</h2>
                <div class="group__input group__dropdown">
                  <p>בחירת חודש</p>
                  <a href="#"
                    ><span>מאי 2023</span>
                    <img src="../../assets/img/arrow.svg" alt="arrow"
                  /></a>
                  <div class="dropdown__box" style="display: none">
                    <ul>
                      <li><a href="#">1</a></li>
                      <li><a href="#">2</a></li>
                      <li><a href="#">3</a></li>
                      <li><a href="#">4</a></li>
                      <li><a href="#">5</a></li>
                      <li><a href="#">1</a></li>
                      <li><a href="#">2</a></li>
                      <li><a href="#">3</a></li>
                      <li><a href="#">4</a></li>
                      <li><a href="#">5</a></li>
                    </ul>
                  </div>
                </div>
                <div class="controls">
                  <button type="submit" class="regular-btn">הנפק דו”ח</button>
                  <a href="#" class="outline-btn cancel__button">ביטול</a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </body>
  </BackofficeBase>
</template>

<script>
import BackofficeBase from "./BackofficeBase";
import BackofficeSidebar from "./BackofficeSidebar";
import BackendModel from "../../models/BackendModel";
// import jsPDF from 'jspdf';
import "jspdf-autotable";
// import html2canvas from 'html2canvas';
import html2pdf from "html2pdf.js";

export default {
  name: "BackofficeUserMonthlyReport",
  components: {
    BackofficeBase,
    BackofficeSidebar,
  },
  data() {
    return {
      loader_prop: true,
      data: [],
      id: "",
      working_date: "",
      is_output_report_exists: false,
      is_hours_report_exists: false,
      worth_of_output_report: "0",
      worth_of_work_hours: "0",
      total: "0",
      table: [],
      total_payment: "0",
      number_of_working_days: "0",
      travel_expenses_days: "0",
      total_hourly: "0",
      total_premium: 0,
      travel_expenses: "0",
      year: "",
      month: "",
      full_month: "",
      amount_to_pay: "",
      total_loans_refund: "",
      full_name: "",
      first_name: "",
      last_name: "",
    };
  },

  async mounted() {
    this.id = this.$route.query.id;
    let backendModel = new BackendModel();
    this.year = new Date().getFullYear();
    this.month = (new Date().getMonth() + 1).toString().padStart(2, "0");
    this.full_month = this.year + "-" + this.month;
    console.log(this.full_month);
    this.data = await backendModel.backendRequest(
      "/Api/service/backoffice/get_user_monthly_report_new_i",
      { id: this.id, month: this.month, year: this.year }
    );
    this.full_name = this.data.data.data.full_name;
    this.first_name = this.data.data.data.first_name;
    this.last_name = this.data.data.data.last_name;
    this.table = this.data.data.data.table;
    this.total_payment = this.data.data.data.total_payment;
    this.number_of_working_days = this.data.data.data.number_of_working_days;
    this.travel_expenses_days = this.data.data.data.travel_expenses_days;
    this.total_payment_for_minimum_daily_hours =
      this.data.data.data.total_payment_for_minimum_daily_hours.toFixed(2);
    this.total_premium = this.data.data.data.total_premium;
    this.travel_expenses = this.data.data.data.travel_expenses;
    this.amount_to_pay = this.data.data.data.amount_to_pay;
    this.total_loans_refund = this.data.data.data.total_loans_refund;
    this.prepay_type = this.data.data.data.prepay_type;
    this.loan_type = this.data.data.data.loan_type;
    // console.log(this.table)
    this.loader_prop = false;
  },

  methods: {
    async handleMonthChange(event) {
      this.loader_prop = true;
      let backendModel = new BackendModel();
      this.full_month = event.target.value;
      this.year = this.full_month.slice(0, 4);
      this.month = this.full_month.slice(5, 7);
      this.data = await backendModel.backendRequest(
        "/Api/service/backoffice/get_user_monthly_report_new_i",
        { id: this.id, month: this.month, year: this.year }
      );
      this.table = this.data.data.data.table;
      this.total_payment = this.data.data.data.total_payment;
      this.number_of_working_days = this.data.data.data.number_of_working_days;
      this.travel_expenses_days = this.data.data.data.travel_expenses_days;
      this.total_payment_for_minimum_daily_hours =
        this.data.data.data.total_payment_for_minimum_daily_hours.toFixed(2);
      this.total_premium = this.data.data.data.total_premium;
      this.travel_expenses = this.data.data.data.travel_expenses;
      this.full_name = this.data.data.data.full_name;
      this.first_name = this.data.data.data.first_name;
      this.last_name = this.data.data.data.last_name;
      this.amount_to_pay = this.data.data.data.amount_to_pay;
      this.total_loans_refund = this.data.data.data.total_loans_refund;
      this.prepay_type = this.data.data.data.prepay_type;
      this.loan_type = this.data.data.data.loan_type;
      this.loader_prop = false;
    },

    createPDF() {
      // Create a div element to hold the table and data items
      const container = document.createElement("div");
      container.classList.add("pdf-container");

      // Add employee name to the top
      const employeeName = document.createElement("h3");
      employeeName.textContent = this.first_name + "   -  " + this.last_name;
      container.appendChild(employeeName);

      // Add table
      const tableData = this.table;
      const table = document.createElement("table");
      table.classList.add("pdf-table");
      const headers = [
        "working_date_to_show",
        "day_to_show",
        "detail_type_he",
        "amount",
        "rate",
        "payment",
      ];

      let totalAmountForMinimumDailyHours = tableData
        .filter((item) => item.detail_type === "minimum_daily_hours")
        .reduce((sum, item) => sum + item.amount, 0);
      totalAmountForMinimumDailyHours =
        totalAmountForMinimumDailyHours.toFixed(2);

      let pay_to_hour = (
        this.total_payment_for_minimum_daily_hours /
        totalAmountForMinimumDailyHours
      ).toFixed(2);
      let dailyTravelExpense = (
        this.travel_expenses / this.travel_expenses_days
      ).toFixed(2);
      const headerRow = document.createElement("tr");
      headers.forEach((header) => {
        const th = document.createElement("th");
        if (header === "working_date_to_show") {
          th.textContent = "תאריך";
        } else if (header === "day_to_show") {
          th.textContent = "יום";
        } else if (header === "code") {
          th.textContent = "קוד משטח";
        } else if (header === "amount") {
          th.textContent = "כמות";
        } else if (header === "price") {
          th.textContent = "מחיר";
        } else if (header === "payment") {
          th.textContent = "תשלום";
        } else if (header === "detail_type_he") {
          th.textContent = "פירוט";
        } else if (header === "rate") {
          th.textContent = "מחיר";
        }
        headerRow.appendChild(th);
      });
      table.appendChild(headerRow);

      //   tableData.forEach((rowData) => {
      //     const row = document.createElement("tr");
      //     headers.forEach((header) => {
      //       const td = document.createElement("td");
      //       td.textContent = rowData[header];
      //       row.appendChild(td);
      //     });
      //     table.appendChild(row);
      //   });

      tableData.forEach((rowData) => {
        const row = document.createElement("tr");
        if (rowData["detail_type_he"] === "סיכום יומי") {
          row.style.backgroundColor = "#d3d3d3"; // אפשר לשנות את הצבע לפי הצורך
        }
        headers.forEach((header) => {
          const td = document.createElement("td");
          td.textContent = rowData[header];
          row.appendChild(td);
        });
        table.appendChild(row);
      });

      container.appendChild(table);

      // Add data items
      const dataItems = [
        { label: " שם   -", value: this.first_name },
        { label: " משפחה   -", value: this.last_name },
        { label: "ימי עבודה  -", value: this.number_of_working_days },
        // {
        //   label: "תשלום שעתי  -",
        //   value: this.total_payment_for_minimum_daily_hours,
        // },
        {
          label:
            "תשלום שעתי  - " +
            pay_to_hour +
            "x" +
            totalAmountForMinimumDailyHours +
            "=" +
            this.total_payment_for_minimum_daily_hours,
        },
        { label: "סך הכל פרמיה  -", value: this.total_premium.toFixed(2) },
        // { label: "מספר ימי נסיעות  -", value: this.travel_expenses_days },
        // { label: "תשלום עבור ימי נסיעות  -", value: this.travel_expenses_days },
        {
          label:
            "תשלום עבור ימי נסיעות  - " +
            dailyTravelExpense +
            "x" +
            this.travel_expenses_days +
            "=" +
            this.travel_expenses,
        },
        { label: "סך הכל תקבולים  -", value: this.total_payment },
        // { label: "הלוואות ומפרעות  -", value: this.total_loans_refund },
        { label: "מפרעות  -", value: this.prepay_type },
        { label: "הלוואות   -", value: this.loan_type },
        // { label: "תשלום על ימי נסיעות  -", value: this.travel_expenses },
        { label: "סך הכל לתשלום  -", value: this.amount_to_pay },
      ];

      const dataContainer = document.createElement("div");
      dataContainer.classList.add("pdf-data-container");

      dataItems.forEach((item) => {
        const dataItem = document.createElement("div");
        dataItem.classList.add("pdf-data-item");
        const label = document.createElement("span");
        label.classList.add("pdf-data-label");
        label.textContent = item.label;
        const value = document.createElement("span");
        value.classList.add("pdf-data-value");
        value.textContent = item.value;
        dataItem.appendChild(label);
        dataItem.appendChild(value);
        dataContainer.appendChild(dataItem);
      });

      container.appendChild(dataContainer);

      // Convert container to PDF using html2pdf
      html2pdf()
        .set({
          margin: 10,
          filename: "document.pdf",
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: { scale: 2, logging: true },
          jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
        })
        .from(container)
        .save();
    },
  },
};
</script>
<style>
.pdf-table {
  border-collapse: collapse;
  width: 100%;
  font-size: 10px;
}

.pdf-table th,
.pdf-table td {
  border: 1px solid black;
  padding: 3px;
}

.pdf-table th {
  background-color: #f2f2f2;
}

.pdf-data-item {
  margin-bottom: 10px;
}

.pdf-data-label {
  font-weight: bold;
}

.pdf-data-value {
  margin-left: 5px;
}

@import "../../assets/new_style.css";
</style>
